.Login {
	width: 600px;
	height: 450px;
	text-align: center;
}

.Login .Button {
	margin: 0 auto;
}

.LoginTitle {
	font-family: 'Lato', sans-serif;
	font-style: italic;
	font-size: 15pt;
	text-transform: uppercase;
	text-align: right;
	padding: 40px;
	background-color: #333;
	color: #eee;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;	
}

.LoginRightTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}


.LoginRegisterLink, .PasswordForgottenLink {
	font-size: 8pt;	
	color: orange;
	cursor: pointer;
}

.LoginRegisterLink:hover, .PasswordForgottenLink:hover {
	text-decoration: underline;
}

.HiddenSubmit {
	display: none;
}

@media only screen and (max-width: 1279px) {
	.Login {
		width: 90%;
		min-width: 0px;
		height: auto;
		padding-bottom: 10px;
	}

	.LoginRightTitle {
		font-size: 15pt;
		line-height: 60px;
	}
}