* {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	font-family: sans-serif;
}

.Wrapper {
  display: flex;
  align-items: center;
}

.Window {
  margin: 40px auto;
}

.FormField {
  width: 200px;
  margin: 10px auto;
}

.FormFieldLabel {
  font-size: 8pt;
  text-align: left;
}

.FormField input {
  padding: 10px;
  width: 200px;
  margin: 0;
  text-align: left;
}

.Button {
  background-color: orange;
  color: white;
  cursor: pointer;
  width: 200px;
  padding: 20px 10px;
  border-radius: 3px;
  opacity: 0.8;
  transition: opacity 0.3s;
  text-align: center;
}

.Button:hover {
  opacity: 1;
}

.Menu {
  position: fixed;
  width: 300px;
  background-color: black;
  color: white;
  padding: 10px;
  height: 100vh;
}

.MenuTitle {
  font-size: 20pt;
  padding: 20px 0;
  border-bottom: 1px solid white;
}

.MenuItem {
  margin: 3px 0;
  padding: 20px 10px;
  background-color: #333;
  cursor: pointer;
  text-transform: uppercase;
}

.MenuItem:hover {
  background-color: orange;
}

.DashboardContent {
  position: absolute;
  left: 360px;
  width: 1200px;
}

.Title {
  font-size: 30pt;
  border-bottom: 2px solid #333;
  margin-bottom: 30px;
}

.CreateAccountForm {
  width: 200px;
}


.AccountsList {
	max-width: 1000px;
	margin: 0 auto;
	position: relative;
}

.AccountsListHeaders {
	width: 100%;
	border-bottom: 2px solid black;
}

.AccountHeader, .ItemListHeader {
	font-family: Georgia, "Times New Roman", serif;
	font-size: 9pt;
	font-weight: bold;
	padding: 5px 0;
	text-transform: uppercase;
	text-align: center;
	display: inline-block;
	border-right: 1px solid #bbb;
	cursor: pointer;
  width: 190px;
}

.AccountHeader:last-child, .ItemListHeader:last-child {	
	border-right: none;
}

.Account, .Item {
	width: 100%;
	border-bottom: 1px solid black;
}

.Account:last-child, .Item:last-child {
	border-bottom: none;	
}

.AccountField, .ItemField {
  width: 190px;
	text-align: center;
	font-size: 8pt;
	padding: 5px 0;
	display: inline-block;
	border-right: 1px solid #bbb;
	vertical-align: middle;
}

.AccountField:last-child, .ItemField:last-child {
	border-right: none;
}