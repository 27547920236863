.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.3s;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Window, .Popup {
	background-color: #fafafa;
	border: 1px solid #333;	
	border-radius: 3px;
    box-shadow: 2px 2px 5px 0px #aaa; 
}

.BugReport {
	width: 50%;
	min-width: 400px;
	padding-bottom: 20px;
	text-align: center;
}

.BugReport .Button {
	margin: 0 auto;
}

.WindowTitle {
	font-family: 'Lato', sans-serif;
	font-style: italic;
	font-size: 15pt;
	text-transform: uppercase;
	text-align: center;
	padding: 40px;
	background-color: #333;
	color: #222;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;	
}

.BugForm {
	width: 70%;
	margin: 0 auto;
}

.BugForm .FormFieldLabel {
	text-align: center;
	font-size: 13pt;
}

.BugDescription {
	font-family: 'Roboto', sans-serif;
	height: 300px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 1px solid #888;
	resize: none;
}

.Popup {
	max-width: 400px;
	padding: 20px;
}

.WindowTopBar, .PopupTopBar {
	height: 30px;
	width: 100%;
	color: white;
	background-color: #333;
}

.WindowClose {
	position: absolute;
	top: 7px;
	right: 7px;
	cursor: pointer;
	transition: color 0.3s;
}

.WindowClose:hover {
	color: orange;
}

.Loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}

.LoadingInfo {
	position: absolute;
	top: 45%;
	left: 150px;
	width: calc(100% - 150px);
	text-align: center;
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	color: orange;
}

.LoadingInfo img {
	width: 50px;
	height: 50px;
	margin: 20px;
}
